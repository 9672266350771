import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { AuthProvider } from "hooks";
import { Flex, Spinner } from "theme-ui";
import EditPseudoRoute from "pages/admin/pseudo-routes/edit-pseudo-route";
import TitleManager from "utils/TitleManager";

const AppLayout = lazy(() => import("app-layout"));
const NotFound = lazy(() => import("pages/errors/NotFound"));
const AuthPage = lazy(() => import("pages/auth"));

// import distribution modules
const RoutingPage = lazy(() => import("pages/distribution/routing"));
const VehiclesPage = lazy(() => import("pages/distribution/fleet-management"));
const VehicleForm = lazy(() =>
  import("pages/distribution/fleet-management/vehicle-form")
);
const TeamAssignment = lazy(() => import("pages/distribution/team-assignment"));
const AssignmentForm = lazy(() =>
  import("pages/distribution/team-assignment/add-team")
);
const RoutePlanForm = lazy(() =>
  import("pages/distribution/team-assignment/add-route-plan")
);
const StockAllocation = lazy(() =>
  import("pages/distribution/stock-allocation")
);
const DynamicRouting = lazy(() => import("pages/distribution/dynamic-routing"));
const AddDynamicRouting = lazy(() =>
  import("pages/distribution/dynamic-routing/add-routing")
);

// import commercial modules
const Shops = lazy(() => import("pages/commercial/shops"));
const ShopForm = lazy(() =>
  import("pages/commercial/shops/components/shop-form")
);
const MoveShops = lazy(() =>
  import("pages/commercial/shops/components/move-shops-form")
);
const Deliveries = lazy(() => import("pages/commercial/deliveries"));
const DeliveryDetail = lazy(() =>
  import("pages/commercial/deliveries/delivery-details")
);
const DeliveryReturns = lazy(() => import("pages/commercial/delivery-returns"));

const ViewOrders = lazy(() => import("pages/commercial/orders"));
const CreateOrder = lazy(() => import("pages/commercial/orders/new-order"));
const OrderReturns = lazy(() => import("pages/commercial/order-returns"));
const OrderReturnsDetail = lazy(() =>
  import("pages/commercial/order-returns/return-details")
);
const SalesTeams = lazy(() => import("pages/commercial/team-assignment"));
const SalesTeamForm = lazy(() =>
  import("pages/commercial/team-assignment/add-team-form")
);
const EKYC = lazy(() => import("pages/commercial/e-kyc"));
const DepotIntake = lazy(() => import("pages/commercial/depot-intake"));
const DepotReturns = lazy(() => import("pages/commercial/depot-returns"));
const DepotStockTransfer = lazy(() =>
  import("pages/commercial/depot-stock-transfer")
);
const AddStockTransfer = lazy(() =>
  import("pages/commercial/depot-stock-transfer/add-transfer")
);
const Warranties = lazy(() => import("pages/commercial/warranties"));
const Clusters = lazy(() => import("pages/commercial/clusters"));
const AddClusters = lazy(() => import("pages/commercial/clusters/add-cluster"));
const ClusterAssignment = lazy(() =>
  import("pages/commercial/clusters-assignment")
);
const AssignClusters = lazy(() =>
  import("pages/commercial/clusters-assignment/assign-cluster")
);
// import report modules
const DeliveryManifest = lazy(() => import("pages/reports/delivery-manifest"));
const VehicleAssignment = lazy(() =>
  import("pages/reports/vehicle-assignment")
);
const SalesPerProduct = lazy(() => import("pages/reports/sales-per-product"));
const VehiclePayments = lazy(() => import("pages/reports/vehicle-payments"));
const DailyOTIF = lazy(() => import("pages/reports/daily-otif"));
const OrderReturnsReport = lazy(() =>
  import("pages/reports/order-returns-report")
);
const PurchasesVsIntakes = lazy(() =>
  import("pages/reports/purchases-vs-intakes")
);
const WMSStockBalances = lazy(() => import("pages/reports/wms-stock-balances"));
const ProductExpiry = lazy(() => import("pages/reports/product-expiry"));
const BookingSummary = lazy(() => import("pages/reports/booking-summary"));
const StockAvailabilityReport = lazy(() =>
  import("pages/reports/stock-availability")
);

// import sourcing modules
const Suppliers = lazy(() => import("pages/sourcing"));
const AddSupplier = lazy(() => import("pages/sourcing/supplier/add-supplier"));
const SupplierDetails = lazy(() => import("pages/sourcing/supplier"));
const Bookings = lazy(() => import("pages/sourcing/bookings"));
const AddBooking = lazy(() => import("pages/sourcing/bookings/add-booking"));
const Purchases = lazy(() => import("pages/sourcing/purchases"));
const AddPurchase = lazy(() => import("pages/sourcing/purchases/add-purchase"));
const AddFarmPurchase = lazy(() =>
  import("pages/sourcing/purchases/add-purchase/add-farm-purchase")
);
const Payments = lazy(() => import("pages/sourcing/payments"));
const PaymentsProvider = lazy(() =>
  import("pages/sourcing/payments/payment-provider")
);
const PriceControl = lazy(() => import("pages/sourcing/price-control"));

// import logistics modules
const LogisticsSuppliers = lazy(() => import("pages/logistics/suppliers"));
const AddLogisticSupplier = lazy(() =>
  import("pages/logistics/suppliers/addSupplier")
);
const VehicleDetails = lazy(() =>
  import("pages/logistics/suppliers/vehicleDetails")
);
const AddSupplierVehicle = lazy(() =>
  import("pages/logistics/suppliers/vehicleDetails/addSupplierVehicles")
);
const Billing = lazy(() => import("pages/logistics/billing"));
const AddBilling = lazy(() => import("pages/logistics/billing/add-billing"));
const BillingDetail = lazy(() =>
  import("pages/logistics/billing/billing-detail")
);
const TripLogs = lazy(() => import("pages/logistics/trip-log"));
const AddTripLog = lazy(() => import("pages/logistics/trip-log/add-trip"));

// import products module
const ProductsMain = lazy(() => import("pages/products/products"));
const ProductDetail = lazy(() =>
  import("pages/products/products/pages/product-detail")
);
const ProductForm = lazy(() =>
  import("pages/products/products/pages/product-form")
);
const ProductBundling = lazy(() =>
  import("pages/products/products/pages/product-bundling")
);
const ProductItem = lazy(() => import("pages/products/products/product-item"));
const AddProductItem = lazy(() =>
  import("pages/products/products/product-item/add-product-item")
);
const ProductItemDetails = lazy(() =>
  import("pages/products/products/product-item/pages")
);
const GeoFencing = lazy(() => import("pages/products/geo-fencing"));
const AddGeoFencing = lazy(() =>
  import("pages/products/geo-fencing/add-geofencing")
);
const Categories = lazy(() => import("pages/products/categories"));
const CategoryDetails = lazy(() => import("pages/products/categories/pages"));
const AddCategory = lazy(() =>
  import("pages/products/categories/add-category")
);
const LossReasons = lazy(() => import("pages/products/loss-reasons"));
const UnitsOfMeasure = lazy(() => import("pages/products/units-of-measure"));
const PackagingUnits = lazy(() => import("pages/products/packaging-units"));
const Departments = lazy(() => import("pages/products/departments"));
const ProductBundles = lazy(() => import("pages/products/product-bundles"));
const AddProductBundle = lazy(() =>
  import("pages/products/product-bundles/add-product-bundle")
);

// import mcommerce modules
const PromoBadges = lazy(() => import("pages/mcommerce/promo-badges"));
const AddPromoBadges = lazy(() =>
  import("pages/mcommerce/promo-badges/add-promo")
);
const OrderingWindow = lazy(() => import("pages/mcommerce/ordering-window"));
const AddOrderWindow = lazy(() =>
  import("pages/mcommerce/ordering-window/add-order-window")
);
const PaymentVisibility = lazy(() =>
  import("pages/mcommerce/payment-visibility")
);
const Promotions = lazy(() => import("pages/mcommerce/promotions"));
const AddPromotion = lazy(() =>
  import("pages/mcommerce/promotions/add-promotion")
);
const PriceMarkdown = lazy(() => import("pages/pricing/price-markdown"));
const UserFeedback = lazy(() => import("pages/mcommerce/user-feedback"));
const PriceMarkdownTable = lazy(() =>
  import("pages/pricing/price-markdown/price-markdown-table")
);
const DynamicPricing = lazy(() => import("pages/pricing/dynamic-pricing"));
const DynamicPricingForm = lazy(() =>
  import("pages/pricing/dynamic-pricing/dynamic-pricing-form")
);
const BasePricing = lazy(() => import("pages/pricing/base-pricing"));
const BasePricingForm = lazy(() =>
  import("pages/pricing/base-pricing/pricing-form")
);
const BasePricingDetails = lazy(() =>
  import("pages/pricing/base-pricing/pricing-details")
);

// import admin modules
const Depots = lazy(() => import("pages/admin/depots"));
const DepotRestrictions = lazy(() => import("pages/admin/depot-restrictions"));
const Regions = lazy(() => import("pages/admin/regions"));
const Areas = lazy(() => import("pages/admin/areas"));
const Routes = lazy(() => import("pages/admin/routes"));
const PseudoRoutes = lazy(() => import("pages/admin/pseudo-routes"));
const NewPseudoRoute = lazy(() =>
  import("pages/admin/pseudo-routes/new-pseudo-route")
);
const AddRoute = lazy(() => import("pages/admin/routes/add-route"));
const DistributionCenters = lazy(() =>
  import("pages/admin/distribution-centers")
);

const DMSUsers = lazy(() => import("pages/admin/users"));
const DMSUserDetailForm = lazy(() =>
  import("pages/admin/users/users/pages/edit-user")
);
const AddDMSUserPage = lazy(() =>
  import("pages/admin/users/users/pages/add-user")
);
const Warehouses = lazy(() => import("pages/admin/warehouses"));
const StorageUnitTypes = lazy(() => import("pages/admin/storage-unit-types"));
const StorageUnits = lazy(() => import("pages/admin/storage-units"));
const FAQS = lazy(() => import("pages/mcommerce/faqs/faq-sections"));

// import warehouse modules
const StockTransfer = lazy(() => import("pages/warehouse/stock-transfer"));
const AddWarehouseTransfer = lazy(() =>
  import("pages/warehouse/stock-transfer/add-transfer")
);
const StockAvailability = lazy(() =>
  import("pages/warehouse/stock-availability")
);

const Appointments = lazy(() => import("pages/warehouse/appointments"));
const AppointmentDetails = lazy(() =>
  import("pages/warehouse/appointments/appointment-details")
);
const AddAppointment = lazy(() =>
  import("pages/warehouse/appointments/add-appointment")
);
const IntakeSheet = lazy(() =>
  import("pages/warehouse/appointments/appointment-details/intake-sheet")
);
const BatchSummary = lazy(() => import("pages/warehouse/batch-summary"));
const DCReturns = lazy(() => import("pages/warehouse/dc-returns"));
const DCReturnsDetail = lazy(() =>
  import("pages/warehouse/dc-returns/dc-returns-detail")
);
const BatchSummaryDetail = lazy(() =>
  import("pages/warehouse/batch-summary/batch-summary-detail")
);
const StockTake = lazy(() => import("pages/warehouse/stock-take"));
const StockIntakTake = lazy(() => import("pages/warehouse/stock-intake"));
const StockIntakTakeDetail = lazy(() =>
  import("pages/warehouse/stock-intake/stock-intake-detail")
);
const ProductLoss = lazy(() => import("pages/warehouse/product-loss"));
const ProductLossDetails = lazy(() =>
  import("pages/warehouse/product-loss/product-loss-details")
);
const WarehouseStorage = lazy(() => import("pages/warehouse/Storage"));
const Dispatch = lazy(() => import("pages/warehouse/dispatch"));
const AddDispatch = lazy(() => import("pages/warehouse/dispatch/add-dispatch"));
const DispatchDetails = lazy(() =>
  import("pages/warehouse/dispatch/dispatch-details")
);

// import WMS modules
const DRSSheet = lazy(() =>
  import("pages/wms/dispatch/order-transfer-details/drs-sheet")
);
const OrderFulfilment = lazy(() => import("pages/wms/dispatch"));
const AddOrderFulfilment = lazy(() =>
  import("pages/wms/dispatch/add-order-transfer")
);
const AddLastMileDispatch = lazy(() =>
  import("pages/wms/dispatch/add-order-transfer/last-mile")
);
const OrderFulfilmentDetails = lazy(() =>
  import("pages/wms/dispatch/order-transfer-details")
);
const DockDoors = lazy(() => import("pages/wms/dock-doors"));
const WarehouseTransfer = lazy(() => import("pages/wms/warehouse-transfer"));
const AddWarehouseTransferRequest = lazy(() =>
  import("pages/wms/warehouse-transfer/add-warehouse-trasnfer")
);
const WarehouseTransferDetails = lazy(() =>
  import("pages/wms/warehouse-transfer/warehouse-transfer-details")
);
const AddDisposal = lazy(() => import("pages/wms/disposals/add-disposal"));
const Disposals = lazy(() => import("pages/wms/disposals"));
const DisposalDetails = lazy(() =>
  import("pages/wms/disposals/disposal-details")
);
const Intake = lazy(() => import("pages/wms/intakes"));
const IntakeDetails = lazy(() => import("pages/wms/intakes/intake-details"));

const Storage = lazy(() => import("pages/wms/storage"));
const AddStorageRule = lazy(() => import("pages/wms/storage/rules/add-rule"));
const StorageRuleDetails = lazy(() =>
  import("pages/wms/storage/rules/rule-details")
);
const AddZone = lazy(() => import("pages/wms/storage/zones/add-zone"));
const ZoneDetails = lazy(() => import("pages/wms/storage/zones/zone-details"));

const WMSAssets = lazy(() => import("pages/wms/wms-assets"));

const Jobs = lazy(() => import("pages/wms/jobs"));

const AgentsList = lazy(() => import("pages/agents/agents"));
const AgentDetails = lazy(() => import("pages/agents/agents/viewAgent"));
const BidApplications = lazy(() => import("pages/agents/bid-applications"));
const ProductBids = lazy(() => import("pages/agents/product-bids"));
const SupplierUsers = lazy(() => import("pages/agents/supplier-users"));
const AddProductBids = lazy(() =>
  import("pages/agents/product-bids/add-product-bid-form")
);
const ProductBidDetails = lazy(() =>
  import("pages/agents/product-bids/bid-details")
);

const TwigaRouter = () => {
  // distribution modules
  const Distribution = [
    <Route
      exact
      path="/"
      key="distribution-home"
      render={() => <Redirect to={"/distribution/route-planning"} />}
    />,
    <Route
      exact
      path="/distribution"
      key="distribution"
      render={() => <Redirect to={"/distribution/route-planning"} />}
    />,
    <Route
      exact
      key="rt-1"
      path="/distribution/route-planning"
      component={RoutingPage}
    />,
    <Route
      exact
      key="veh-1"
      path="/distribution/vehicles"
      component={VehiclesPage}
    />,
    <Route
      exact
      key="veh-2"
      path="/distribution/vehicles/new"
      component={VehicleForm}
    />,
    <Route
      key="veh-3"
      path="/distribution/vehicles/:vehicle_id"
      component={VehicleForm}
    />,
    <Route
      exact
      key="team-1"
      path="/distribution/team-assignment"
      component={TeamAssignment}
    />,
    <Route
      key="team-2"
      path="/distribution/team-assignment/new"
      component={AssignmentForm}
    />,
    <Route
      key="team-3"
      path="/distribution/team-assignment/route_plan/new"
      component={RoutePlanForm}
    />,
    <Route
      key="stock-1"
      path="/distribution/stock-allocation"
      component={StockAllocation}
    />,
    <Route
      exact
      key="dyn-1"
      path="/distribution/dynamic-routing"
      component={DynamicRouting}
    />,
    <Route
      key="dyn-2"
      path="/distribution/dynamic-routing/new"
      component={AddDynamicRouting}
    />,
    <Route
      exact
      key="psd-1"
      path="/distribution/pseudo-routes"
      component={PseudoRoutes}
    />,
    <Route
      exact
      key="psd-2"
      path="/distribution/pseudo-routes/new-pseudo-route"
      component={NewPseudoRoute}
    />,
    <Route
      exact
      key="psd-3"
      path="/distribution/pseudo-routes/:id"
      component={EditPseudoRoute}
    />
  ];

  // commercial modules
  const Commercial = [
    <Route
      exact
      path="/"
      key="commercial-home"
      render={() => <Redirect to={"/commercial/shops"} />}
    />,
    <Route
      exact
      path="/commercial"
      key="commercial"
      render={() => <Redirect to={"/commercial/shops"} />}
    />,
    <Route exact key="shop-1" path="/commercial/shops" component={Shops} />,
    <Route
      exact
      key="shop-2"
      path="/commercial/shops/new"
      component={ShopForm}
    />,
    <Route
      key="shop-3"
      exact
      path="/commercial/shops/:shop_id"
      component={ShopForm}
    />,
    <Route
      key="shop-4"
      exact
      path="/commercial/shops/move-shops/new"
      component={MoveShops}
    />,
    <Route
      exact
      key="del-1"
      path="/commercial/deliveries"
      component={Deliveries}
    />,
    <Route
      key="del-2"
      path="/commercial/deliveries/:delivery_id"
      component={DeliveryDetail}
    />,
    <Route
      exact
      key="del-3"
      path="/commercial/delivery-returns"
      component={DeliveryReturns}
    />,
    <Route
      exact
      key="ord-1"
      path="/commercial/orders"
      component={ViewOrders}
    />,
    <Route
      exact
      key="ord-4"
      path="/commercial/orders/new-order"
      component={CreateOrder}
    />,
    <Route
      exact
      key="ord-2"
      path="/commercial/order-returns"
      component={OrderReturns}
    />,
    <Route
      key="ord-3"
      path="/commercial/order-returns/:return_id"
      component={OrderReturnsDetail}
    />,
    <Route exact key="tdr-1" path="/commercial/teams" component={SalesTeams} />,
    <Route
      exact
      key="tdr-2"
      path="/commercial/teams/assign"
      component={SalesTeamForm}
    />,
    <Route exact key="ekyc-1" path="/commercial/ekyc" component={EKYC} />,
    <Route
      exact
      key="depot-intake"
      path="/commercial/depot-intake"
      component={DepotIntake}
    />,
    <Route
      exact
      key="depot-returns"
      path="/commercial/depot-returns"
      component={DepotReturns}
    />,
    <Route
      exact
      key="stock-transfer"
      path="/commercial/stock-transfer"
      component={DepotStockTransfer}
    />,
    <Route
      exact
      key="stock-transfer"
      path="/commercial/stock-transfer/new"
      component={AddStockTransfer}
    />,
    <Route
      exact
      key="warranties"
      path="/commercial/warranties"
      component={Warranties}
    />,
    <Route
      exact
      key="clusters"
      path="/commercial/clusters"
      component={Clusters}
    />,
    <Route
      exact
      key="add-clusters"
      path="/commercial/clusters/add"
      component={AddClusters}
    />,
    <Route
      exact
      key="cluster-assignment"
      path="/commercial/cluster-assignment"
      component={ClusterAssignment}
    />,
    <Route
      exact
      key="assign-clusters"
      path="/commercial/cluster-assignment/assign"
      component={AssignClusters}
    />
  ];

  const Reports = [
    <Route
      exact
      key="reports"
      path="/reports/delivery-manifest"
      component={DeliveryManifest}
    />,
    <Route
      exact
      key="vehicle-assignment"
      path="/reports/vehicle-assignment"
      component={VehicleAssignment}
    />,
    <Route
      exact
      key="reports-sales"
      path="/reports/sales-per-product"
      component={SalesPerProduct}
    />,
    <Route
      exact
      key="vehicle-payments"
      path="/reports/vehicle-payments"
      component={VehiclePayments}
    />,
    <Route
      exact
      key="daily-otif"
      path="/reports/daily-otif"
      component={DailyOTIF}
    />,
    <Route
      exact
      key={"order-returns-report"}
      path="/reports/order-returns-report"
      component={OrderReturnsReport}
    />,
    <Route
      exact
      key="purchases-vs-intakes"
      path="/reports/purchases-vs-intakes"
      component={PurchasesVsIntakes}
    />,
    <Route
      exact
      key="wms-stock-balances"
      path="/reports/wms-stock-balances"
      component={WMSStockBalances}
    />,
    <Route
      exact
      key="product-expiry"
      path="/reports/product-expiry"
      component={ProductExpiry}
    />,
    <Route
      exact
      key="booking-summary"
      path="/reports/booking-summary"
      component={BookingSummary}
    />,
    <Route
      exact
      key="stock-avilability-report"
      path="/reports/stock-availability"
      component={StockAvailabilityReport}
    />
  ];

  // pricing modules
  const Pricing = [
    <Route
      exact
      key="pricing"
      path="/pricing"
      render={() => <Redirect to={"/pricing/base-pricing"} />}
    />,
    <Route
      exact
      key="base-pricing"
      path="/pricing/base-pricing"
      component={BasePricing}
    />,
    <Route
      exact
      key="base-pricing-new"
      path="/pricing/base-pricing/new"
      component={BasePricingForm}
    />,
    <Route
      exact
      key="base-pricing-details"
      path="/pricing/base-pricing/details/:product_item_id"
      component={BasePricingDetails}
    />,
    <Route
      exact
      path="/"
      key="pricing-home"
      render={() => <Redirect to={"/pricing/price-markdown-table"} />}
    />,
    <Route
      exact
      path="/price-markdown"
      key="pricing"
      render={() => <Redirect to={"/pricing/price-markdown-table"} />}
    />,

    <Route
      exact
      key="price-markdown"
      path="/pricing/price-markdown"
      component={PriceMarkdown}
    />,
    <Route
      exact
      key="price-markdown-table"
      path="/pricing/price-markdown-table"
      component={PriceMarkdownTable}
    />,
    <Route
      exact
      key="price-markdown-table"
      path="/pricing/dynamic-pricing"
      component={DynamicPricing}
    />,
    <Route
      exact
      key="price-markdown-table"
      path="/pricing/dynamic-pricing/new"
      component={DynamicPricingForm}
    />
  ];

  // sourcing modules
  const Sourcing = [
    <Route
      exact
      path="/"
      key="sourcing-home"
      render={() => <Redirect to={"/sourcing/suppliers"} />}
    />,
    <Route
      exact
      path="/sourcing"
      key="sourcing"
      render={() => <Redirect to={"/sourcing/suppliers"} />}
    />,
    <Route
      exact
      key="supp-1"
      path="/sourcing/suppliers"
      component={Suppliers}
    />,
    <Route
      exact
      key="supp-2"
      path="/sourcing/suppliers/new"
      component={AddSupplier}
    />,
    <Route
      key="supp-3"
      component={SupplierDetails}
      path="/sourcing/suppliers/:supplier_id"
    />,
    <Route exact key="bk-1" path="/sourcing/bookings" component={Bookings} />,
    <Route
      exact
      key="bk-2"
      path="/sourcing/bookings/new"
      component={AddBooking}
    />,
    <Route
      exact
      key="purchase-1"
      path="/sourcing/purchases"
      component={Purchases}
    />,
    <Route
      exact
      key="purchase-2"
      path="/sourcing/purchases/new"
      component={AddPurchase}
    />,
    <Route
      exact
      key="purchase-3"
      path="/sourcing/purchases/farm"
      component={AddFarmPurchase}
    />,
    <Route
      exact
      key="payments-1"
      path="/sourcing/payments"
      component={Payments}
    />,
    <Route
      exact
      key="price-control"
      path="/sourcing/price-control"
      component={PriceControl}
    />
  ];

  // logistics modules
  const Logistics = [
    <Route
      exact
      path="/"
      key="logistics-home"
      render={() => <Redirect to={"/logistics/suppliers"} />}
    />,
    <Route
      exact
      path="/logistics"
      key="logistics"
      render={() => <Redirect to={"/logistics/suppliers"} />}
    />,
    <Route
      exact
      key="log-1"
      path="/logistics/suppliers"
      component={LogisticsSuppliers}
    />,
    <Route
      exact
      key="log-2"
      path="/logistics/suppliers/new"
      component={AddLogisticSupplier}
    />,
    <Route
      exact
      key="log-4"
      component={AddSupplierVehicle}
      path="/logistics/suppliers/:supplier_id/new"
    />,
    <Route
      exact
      key="log-3"
      component={VehicleDetails}
      path="/logistics/suppliers/:supplier_id"
    />,
    <Route
      key="log-5"
      exact
      component={TripLogs}
      path="/logistics/trip-logs"
    />,
    <Route
      key="log-6"
      exact
      component={AddTripLog}
      path="/logistics/trip-logs/new"
    />,
    <Route exact key="log-7" path="/logistics/billing" component={Billing} />,
    <Route
      exact
      key="log-8"
      path="/logistics/billing/new"
      component={AddBilling}
    />,
    <Route
      exact
      key="log-9"
      path="/logistics/billing/:statement_id"
      component={BillingDetail}
    />
  ];

  // warehouse modules
  const Warehouse = [
    <Route
      exact
      path="/"
      key="warehouse-home"
      render={() => <Redirect to={"/warehouse/warehouse-transfer"} />}
    />,
    <Route
      exact
      path="/warehouse"
      key="warehouse"
      render={() => <Redirect to={"/warehouse/warehouse-transfer"} />}
    />,
    <Route
      exact
      key="wht-1"
      path="/warehouse/warehouse-transfer"
      component={StockTransfer}
    />,
    <Route
      exact
      key="wht-2"
      path="/warehouse/warehouse-transfer/new"
      component={AddWarehouseTransfer}
    />,
    <Route
      exact
      key="wht-3"
      path="/warehouse/stock-availability"
      component={StockAvailability}
    />,
    <Route
      exact
      key="wht-3"
      path="/warehouse/batches"
      component={BatchSummary}
    />,
    <Route
      exact
      key="wht-4"
      path="/warehouse/dc-returns"
      component={DCReturns}
    />,
    <Route
      exact
      key="wht-4"
      path="/warehouse/dc-returns/:return_id"
      component={DCReturnsDetail}
    />,
    <Route
      exact
      key="wht-5"
      path="/warehouse/product-loss"
      component={ProductLoss}
    />,
    <Route
      exact
      key="wht-6"
      path="/warehouse/product-loss/:processing_loss_id"
      component={ProductLossDetails}
    />,
    <Route
      exact
      key="wht-7"
      path="/warehouse/stock-take"
      component={StockTake}
    />,
    <Route
      exact
      key="wht-12"
      path="/warehouse/dispatch"
      component={Dispatch}
    />,
    <Route
      exact
      key="wht-13"
      path="/warehouse/dispatch/new"
      component={AddDispatch}
    />,
    <Route
      exact
      key="wht-14"
      path="/warehouse/dispatch/:dispatch_id"
      component={DispatchDetails}
    />,
    <Route
      exact
      key="wht-15"
      path="/warehouse/storage"
      component={WarehouseStorage}
    />,
    <Route
      exact
      key="wht-10"
      path="/warehouse/stock-intake"
      component={StockIntakTake}
    />,
    <Route
      exact
      key="wht-11"
      path="/warehouse/stock-intake/:intake_id"
      component={StockIntakTakeDetail}
    />,
    <Route
      exact
      key="wht-15"
      path="/warehouse/storage"
      component={WarehouseStorage}
    />,
    <Route
      exact
      key="wht-16"
      path="/warehouse/batches/:id"
      component={BatchSummaryDetail}
    />
  ];

  // WMS modules
  const WMS = [
    <Route
      exact
      path="/"
      key="wms-home"
      render={() => <Redirect to={"/wms/appointments"} />}
    />,
    <Route
      exact
      path="/wms"
      key="wms"
      render={() => <Redirect to={"/wms/appointments"} />}
    />,
    <Route
      exact
      key="app-1"
      path="/wms/appointments"
      component={Appointments}
    />,
    <Route
      exact
      key="app-2"
      path="/wms/appointments/new"
      component={AddAppointment}
    />,
    <Route
      exact
      key="app-3"
      path="/wms/appointments/:appointment_id"
      component={AppointmentDetails}
    />,
    <Route
      exact
      key="app-4"
      path="/wms/appointment/intake-sheet"
      component={IntakeSheet}
    />,
    <Route
      exact
      key="app-4"
      path="/wms/dispatch/drs-sheet"
      component={DRSSheet}
    />,
    <Route exact key="int-1" path="/wms/intakes" component={Intake} />,
    <Route
      exact
      key="int-2"
      path="/wms/intakes/:stock_intake_id"
      component={IntakeDetails}
    />,
    <Route
      exact
      key="oft-1"
      path="/wms/dispatch"
      component={OrderFulfilment}
    />,
    <Route
      exact
      key="oft-2"
      path="/wms/dispatch/new"
      component={AddOrderFulfilment}
    />,
    <Route
      exact
      key="oft-4"
      path="/wms/dispatch/last-mile/new"
      component={AddLastMileDispatch}
    />,
    <Route
      exact
      key="oft-3"
      path="/wms/dispatch/:fulfilment_id"
      component={OrderFulfilmentDetails}
    />,
    <Route exact key="dock-1" path="/wms/dock-doors" component={DockDoors} />,
    <Route
      exact
      key="wt-1"
      path="/wms/warehouse-transfer"
      component={WarehouseTransfer}
    />,
    <Route
      exact
      key="wt-2"
      path="/wms/warehouse-transfer/new"
      component={AddWarehouseTransferRequest}
    />,
    <Route
      exact
      key="wt-3"
      path="/wms/warehouse-transfer/:warehouse_transfer_request_id"
      component={WarehouseTransferDetails}
    />,
    <Route exact key="dd-1" path="/wms/disposals" component={Disposals} />,
    <Route
      exact
      key="dd-2"
      path="/wms/disposals/new"
      component={AddDisposal}
    />,
    <Route
      exact
      key="dd-3"
      path="/wms/disposals/:disposal_id"
      component={DisposalDetails}
    />,
    <Route exact key="st-1" path="/wms/storage" component={Storage} />,
    <Route
      exact
      key="st-2"
      path="/wms/storage/rule"
      component={AddStorageRule}
    />,
    <Route exact key="st-3" path="/wms/storage/zones" component={AddZone} />,
    <Route
      exact
      key="st-4"
      path="/wms/storage/zones/:product_zone_id"
      component={ZoneDetails}
    />,
    <Route
      exact
      key="st-5"
      path="/wms/storage/:rule_id"
      component={StorageRuleDetails}
    />,
    <Route exact key="pt-1" path="/wms/pallets" component={WMSAssets} />,
    // <Route exact key="lb-1" path="/wms/labels" component={Labels} />,
    <Route exact key="jb-1" path="/wms/jobs" component={Jobs} />
  ];

  const Mcommerce = [
    <Route
      exact
      path="/"
      key="mcommerce-home"
      render={() => <Redirect to={"/mcommerce/promo-badges"} />}
    />,
    <Route
      exact
      path="/mcommerce"
      key="mcommerce"
      render={() => <Redirect to={"/mcommerce/promo-badges"} />}
    />,
    <Route
      exact
      key="promo-badge"
      path="/mcommerce/promo-badges"
      component={PromoBadges}
    />,
    <Route
      exact
      key="new-promo-badge"
      path="/mcommerce/promo-badges/new"
      component={AddPromoBadges}
    />,
    <Route
      exact
      key="order-window"
      path="/mcommerce/ordering-window"
      component={OrderingWindow}
    />,
    <Route
      exact
      key="new-order-window"
      path="/mcommerce/ordering-window/new"
      component={AddOrderWindow}
    />,
    <Route
      exact
      key="payment-visibility"
      path="/mcommerce/payment-visibility"
      component={PaymentVisibility}
    />,
    <Route
      exact
      key="promotions"
      path="/mcommerce/promotions"
      component={Promotions}
    />,
    <Route
      exact
      key="new-promotion"
      path="/mcommerce/promotions/new"
      component={AddPromotion}
    />
  ];

  // admin modules
  const Admin = [
    <Route
      exact
      path="/"
      key="admin-home"
      render={() => <Redirect to={"/admin/regions"} />}
    />,
    <Route
      exact
      path="/admin"
      key="admin"
      render={() => <Redirect to={"/admin/regions"} />}
    />,
    <Route exact key="adm-1" path="/admin/depots" component={Depots} />,

    <Route exact key="adm-2" path="/admin/regions" component={Regions} />,
    <Route exact key="admin-users" path="/admin/users" component={DMSUsers} />,
    <Route
      exact
      key="user-details"
      path="/admin/users/:user_id"
      component={DMSUserDetailForm}
    />,

    <Route
      exact
      key="new-users"
      path="/admin/users/add/new"
      component={AddDMSUserPage}
    />,
    <Route exact key="adm-3" path="/admin/areas" component={Areas} />,
    <Route exact key="adm-4" path="/admin/routes" component={Routes} />,
    <Route exact key="adm-5" path="/admin/routes/new" component={AddRoute} />,
    <Route
      exact
      key="adm-6"
      path="/admin/distribution-centers"
      component={DistributionCenters}
    />,
    <Route exact key="adm-7" path="/admin/warehouses" component={Warehouses} />,
    <Route
      exact
      key="adm-8"
      path="/admin/storage-unit-types"
      component={StorageUnitTypes}
    />,
    <Route
      exact
      key="adm-9"
      path="/admin/storage-units"
      component={StorageUnits}
    />,
    <Route exact key="adm-10" path="/mcommerce/faqs" component={FAQS} />,
    <Route
      exact
      key="adm-10"
      path="/mcommerce/user-feedback"
      component={UserFeedback}
    />,
    <Route
      exact
      key="adm-11"
      path="/admin/depot-restrictions"
      component={DepotRestrictions}
    />,
    <Route
      exact
      key="adm-12"
      path="/admin/pseudo-routes"
      component={PseudoRoutes}
    />,
    <Route
      exact
      key="adm-13"
      path="/admin/pseudo-routes/new-pseudo-route"
      component={NewPseudoRoute}
    />,
    <Route
      exact
      key="adm-13"
      path="/admin/pseudo-routes/:id"
      component={EditPseudoRoute}
    />
  ];

  const Products = [
    <Route
      exact
      path="/"
      key="products-home"
      render={() => <Redirect to={"/products/products"} />}
    />,
    <Route
      exact
      path="/products"
      key="prod1"
      render={() => <Redirect to={"/products/products"} />}
    />,
    <Route
      exact
      key="products"
      path="/products/products"
      component={ProductsMain}
    />,
    <Route
      exact
      key="edit-product"
      path="/products/products/:product_id/edit"
      component={ProductForm}
    />,
    <Route
      exact
      key="add-product"
      path="/products/products/new"
      component={ProductForm}
    />,
    <Route
      exact
      key="product-bundle"
      path="/products/products/bundle-configuration/new"
      component={ProductBundling}
    />,
    <Route
      exact
      key="product-detail"
      path="/products/products/:product_id"
      component={ProductDetail}
    />,
    <Route
      exact
      key="prod-item"
      path="/products/products/product_item/:product_id"
      component={ProductItem}
    />,
    <Route
      exact
      key="prod-item3"
      path="/products/products/:product_id/new"
      component={AddProductItem}
    />,
    <Route
      exact
      key="prod-item2"
      path="/products/products/:product_id/:product_item_id"
      component={ProductItemDetails}
    />,
    <Route
      exact
      key="geofencing"
      path="/products/geo-fencing"
      component={GeoFencing}
    />,
    <Route
      exact
      key="add-geofence"
      path="/products/geo-fencing/new"
      component={AddGeoFencing}
    />,
    <Route
      exact
      key="prod-cat"
      path="/products/categories"
      component={Categories}
    />,

    <Route
      exact
      key="prod-cat-new"
      path="/products/categories/new"
      component={AddCategory}
    />,
    <Route
      exact
      key="prod-cat2"
      path="/products/categories/:category_id"
      component={CategoryDetails}
    />,
    <Route
      exact
      key="prod-uom"
      path="/products/units-of-measure"
      component={UnitsOfMeasure}
    />,
    <Route
      exact
      key="prod-loss-reasons"
      path="/products/loss-reasons"
      component={LossReasons}
    />,
    <Route
      exact
      key="packaging"
      path="/products/packaging-units"
      component={PackagingUnits}
    />,
    <Route
      exact
      key="department"
      path="/products/departments"
      component={Departments}
    />,
    <Route
      exact
      key="prod-bundle"
      path="/products/product-bundles"
      component={ProductBundles}
    />,
    <Route
      exact
      key="prod-bundle"
      path="/products/product-bundles/add-product-bundle"
      component={AddProductBundle}
    />
  ];

  const Agents = [
    <Route
      exact
      path="/agents"
      key="agents-home"
      render={() => <Redirect to={"/agents/agents"} />}
    />,
    <Route exact key="agents" path="/agents/agents" component={AgentsList} />,
    <Route
      exact
      key="agents"
      path="/agents/agent/:agent_id"
      component={AgentDetails}
    />,
    <Route
      exact
      key="agents"
      path="/agents/bid-applications"
      component={BidApplications}
    />,
    <Route
      exact
      key="agents"
      path="/agents/product-bids"
      component={ProductBids}
    />,
    <Route
      exact
      key="agents"
      path="/agents/product-bids/new"
      component={AddProductBids}
    />,
    <Route
      exact
      key="agents"
      component={ProductBidDetails}
      path="/agents/product-bid/:bid_id"
    />,
    <Route
      exact
      key="agents"
      path="/agents/supplier-users"
      component={SupplierUsers}
    />
  ];

  return (
    <AuthProvider>
      <Suspense
        fallback={
          <Flex
            style={{
              width: "100%",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spinner />
          </Flex>
        }
      >
        <Router>
          <TitleManager />
          <Switch>
            <Route exact path="/auth">
              <Redirect to="/auth/login" />
            </Route>
            <Route path="/auth">
              <AuthPage />
            </Route>
            <AppLayout>
              <PaymentsProvider>
                <Switch>
                  {Distribution}
                  {Commercial}
                  {Admin}
                  {Pricing}
                  {Sourcing}
                  {Agents}
                  {Logistics}
                  {Products}
                  {Mcommerce}
                  {Warehouse}
                  {WMS}
                  {Reports}
                  <Route component={NotFound} />
                </Switch>
              </PaymentsProvider>
            </AppLayout>
          </Switch>
        </Router>
      </Suspense>
    </AuthProvider>
  );
};

export default TwigaRouter;
